<template>
  <section
    class="add-team absolute w-full h-full z-10"
    @click="clickOnBackground"
  >
    <div
      class="popup-container rounded"
    >
      <div
        class="popup rounded"
        @click.prevent.stop
      >
        <slot @click.prevent.stop />
      </div>
    </div>
  </section>
</template>

<script>export default {
  name: 'PopupBase',
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
    clickOnBackground () {
      this.$emit('clickOnBackground', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/mixin";
.add-team {
  background-color: rgba(0,0,0,0.2);
  display: table;
  top: 0;
  left: 0;
  position: fixed
}
.popup-container {
  display: table-cell;
  vertical-align: middle;
  position: absolute;
  right: 95px;
  margin-top: 40px;
}
.popup {
  width: 100%;

}
 @media screen and (max-width: 540px){
      .popup {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
}
</style>
